// Main Base
.app-main__outer {
    flex: 1;
    flex-direction: column;
    display: flex;
    z-index: 8;
    padding: $app-header-height 0 0 $app-sidebar-width;
    transition: all .2s;
}

.app-main__inner {
    padding: $grid-gutter-width $grid-gutter-width 0;
    flex: 1;
}

.dashboard {
    text-align: center;
    margin-top: 250px;
    font-size: 56px;
}

// Themes
@import "themes/main-white";