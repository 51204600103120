// Sidebar Base
// Variables
$primaryColor: #4285f4 !default;
$baseBg: #2a2a2e !default;
$darkenBg: darken($baseBg, 5%) !default;
$lightenBg: lighten($baseBg, 5%) !default;
$itemColor: #fff !default;
$itemOpenColor: #fff !default;
$itemOpenBg: $primaryColor !default;
$itemHoverColor: #fff !default;
$itemHoverBg: rgba($darkenBg, 0.5) !default;
$iconColor: #fff !default;
$iconBg: $darkenBg !default;
$iconActiveColor: #fff !default;
$iconActiveBg: $darkenBg !default;
$iconOpenColor: #fff !default;
$iconOpenBg: transparent !default;
$mobileItemColor: #fff !default;
$mobileItemBg: $primaryColor !default;
$mobileIconBg: transparent !default;
$mobileIconColor: #fff !default;
$dropDownColor: #fff !default;
$dropDownBg: $lightenBg !default;
$sidebar-box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);
.app-sidebar {
    display: flex;
    z-index: 20;
    overflow: hidden;
    width: $app-sidebar-width;
    position: fixed;
    height: 100vh;
    box-shadow: $sidebar-box-shadow;
    flex-direction: column;
    transition: all .3s ease;
    .app-header__logo {
        padding: 0 $layout-spacer-x;
        height: $app-header-height;
        width: $app-sidebar-width;
        display: flex;
        align-items: center;
        transition: width .2s;
        flex-shrink: 0;
        background-color: #02305c !important;
    }
    .app-sidebar-content {
        flex-grow: 1;
        display: flex;
        width: 100%;
        .app-sidebar-scroll {
            width: 100%;
            white-space: nowrap;
            ng-scrollbar.ng-scroll-viewport.ng-scrollbar {
                height: calc(100vh - 45px);
            }
        }
    }
    .accordion .card {
        box-shadow: 0 0 0 0 transparent;
        border: 0;
        background: transparent;
        position: relative;
        display: block;
        padding: 0.1rem 0;
        .card-body {
            padding: 0;
        }
        .card-header {
            padding: 0;
            margin: 0;
            @include border-radius(0);
            border: 0;
            height: auto;
            background: transparent;
        }
    }
}

// .logo-src {
//     height: $logo-height;
//     width: $logo-width;
//     font-size: large;
//     font-style: bold, italic;
//     font-family: Arial, Helvetica, sans-serif;
//     background: url('/assets/images/1024.png');
// }
.logo-src {
    margin: 0 auto;
    height: 80px;
    width: 105px;
    background: url(/assets/images/logo.png);
    background-size: 89px;
    background-repeat: no-repeat;
}

// Sidebar Menu
.v-sidebar-menu {
    width: auto !important;
    padding: 0 $layout-spacer-x $layout-spacer-x;
    %side-link-hover {
        background: $dropdown-link-hover-bg;
        color: $primary;
    }
    & .vsm-link {
        display: block;
        line-height: 2.4rem;
        height: 2.4rem;
        padding: 0 1.5rem 0 45px;
        position: relative;
        border-radius: .25rem;
        color: $gray-700;
        white-space: nowrap;
        transition: all .2s;
        text-decoration: none;
        border: 0;
        background: transparent;
        width: 100%;
        text-align: left;
        cursor: pointer;
        font-weight: 600;
        &:hover {
            @extend %side-link-hover;
        }
        &.active-item {
            font-weight: 600;
            @extend %side-link-hover;
        }
        &[disabled] {
            opacity: 0.4;
            pointer-events: none;
        }
        &.collapsed {
            font-weight: normal;
            .vsm-arrow {
                transform: rotate(0deg);
                font-weight: bold;
            }
        }
    }
    & .vsm-title {
        white-space: nowrap;
        position: relative;
    }
    & .vsm-icon {
        text-align: center;
        width: 34px;
        height: 34px;
        line-height: 34px;
        position: absolute;
        left: 5px;
        top: 50%;
        margin-top: -17px;
        font-size: 1.5rem;
        opacity: .3;
        transition: color 300ms;
    }
    & .vsm-arrow {
        text-align: center;
        width: 34px;
        height: 34px;
        line-height: 34px;
        position: absolute;
        right: 5px;
        top: 50%;
        margin-top: -17px;
        font-size: 1.5rem;
        font-style: normal;
        opacity: .3;
        transition: all 300ms;
        transform: rotate(180deg);
        &:after {
            content: '\e688';
            font-family: 'Pe-icon-7-stroke';
        }
    }
    & .vsm-dropdown {
        position: relative;
        &::before {
            content: '';
            height: 100%;
            opacity: 1;
            width: 3px;
            background: $dropdown-link-hover-bg;
            position: absolute;
            left: 20px;
            top: 0;
            border-radius: 15px;
        }
        .vsm-list {
            padding: ($layout-spacer-x / 2) 0 ($layout-spacer-x / 2) $layout-spacer-x * 1.5;
        }
        .vsm-item {
            .vsm-link {
                padding: 0 ($layout-spacer-x / 1.5);
                height: 1.9rem;
                line-height: 1.9rem;
                font-weight: normal;
                &.active-item {
                    font-weight: 600;
                }
            }
        }
    }
    & .vsm-header {
        text-transform: uppercase;
        font-size: $font-size-xs;
        margin: ($layout-spacer-x / 2) 0;
        font-weight: bold;
        color: $primary;
        white-space: nowrap;
        position: relative;
    }
    & .collapse-btn {
        display: none;
    }
    .collapse {
        // transition: all .26s ease;
        transform: scaleY(0);
        transform-origin: top;
        height: auto;
        max-height: 0;
        display: block !important;
    }
    .collapse.show {
        transform: scaleY(1);
        max-height: 600px;
    }
    .vsm-list>.vsm-item {
        .vsm-link {
            font-weight: normal;
            &.active-item {
                font-weight: bold;
            }
        }
    }
}

.sidebar-menu-overlay {
    position: fixed;
    height: 0%;
    width: 0%;
    z-index: 12;
    background: rgba(0, 0, 0, 0.3);
    display: block;
    content: '';
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s;
}

.closed-sidebar-md.closed-sidebar-open {
    .sidebar-menu-overlay {
        visibility: visible;
        opacity: 1;
        height: 100%;
        width: 100%;
    }
}

.selected-item {
    font-weight: 700;
    font-size: large;
}

.check {
    position: relative;
    width: 50px;
    height: 24px;
    background: #c6c6c6;
    outline: none;
    border-radius: 75px;
    box-shadow: inset 0 0 5px rgb(255 0 0 / 20%);
    transition: 0.7s;
}

// Modifiers
@import "modifiers/closed-sidebar";
// Sidebar Themes
@import "themes/sidebar-light";
@import "themes/sidebar-dark";