.check {
    input[type="checkbox"] {
        position: relative;
        width: 50px;
        height: 24px;
        -webkit-appearance: none;
        background: #c6c6c6;
        outline: none;
        border-radius: 20px;
        box-shadow: inset 0 0 5px rgba(255, 0, 0, 0.2);
        transition: 0.7s;
    }
    input:checked[type="checkbox"] {
        background: #49be25;
    }
    input[type="checkbox"]:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 21px;
        border-radius: 20px;
        top: 0;
        left: 0;
        background: #ffffff;
        transform: scale(1.1);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        transition: .5s;
    }
    input:checked[type="checkbox"]:before {
        left: 30px;
    }
}

input[type="checkbox"] {
    position: relative;
    width: 22px;
    height: 24px;
    background: #c6c6c6;
    outline: none;
    border-radius: 20px;
    box-shadow: inset 0 0 5px rgba(255, 0, 0, 0.2);
    transition: 0.7s;
}

input:checked[type="checkbox"] {
    background: #02305c !important;
}

.pageNumber {
    text-align: left;
    position: relative;
    top: 10px;
    right: -55px;
    width: 100px;
}