// User Pages
.app-logo {
    height: $logo-height;
    width: $logo-width;
    background: url('/assets/images/logo-inverse.png');
}

.app-logo-inverse {
    height: 89px;
    width: 200px;
    background: url('/assets/images/Rectangle.png');
}

.app-login-box {
    .app-logo {
        margin-bottom: $layout-spacer-lg;
    }
    h4 {
        margin-bottom: $layout-spacer-x;
        font-weight: normal;
        div {
            opacity: .6;
        }
        span {
            font-size: $font-size-lg;
        }
    }
}