@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "assets/base";
.TutorDetailForm {
    .selected-item {
        font-size: 14px !important;
    }
}
.cdk-overlay-container{
    z-index: 9999;
}
.owl-dt-control .owl-dt-control-content{
    color: #033C73;
}