// scss-docs-start caret-mixins
@mixin caret-down {
    border-top: $caret-width solid;
    border-right: $caret-width solid transparent;
    border-bottom: 0;
    border-left: $caret-width solid transparent;
}

@mixin caret-up {
    border-top: 0;
    border-right: $caret-width solid transparent;
    border-bottom: $caret-width solid;
    border-left: $caret-width solid transparent;
}

@mixin caret-end {
    border-top: $caret-width solid transparent;
    border-right: 0;
    border-bottom: $caret-width solid transparent;
    border-left: $caret-width solid;
}

@mixin caret-start {
    border-top: $caret-width solid transparent;
    border-right: $caret-width solid;
    border-bottom: $caret-width solid transparent;
}

@mixin caret($direction: down) {
    @if $enable-caret {
        &::after {
            display: none !important;
            margin-left: $caret-spacing;
            vertical-align: $caret-vertical-align;
            content: "";
            @if $direction==down {
                @include caret-down();
            }
            @else if $direction==up {
                @include caret-up();
            }
            @else if $direction==end {
                @include caret-end();
            }
        }
        @if $direction==start {
            &::after {
                display: none;
            }
            &::before {
                display: inline-block;
                margin-right: $caret-spacing;
                vertical-align: $caret-vertical-align;
                content: "";
                @include caret-start();
            }
        }
        &:empty::after {
            margin-left: 0;
        }
    }
}

// scss-docs-end caret-mixins
// .dropdown-toggle::after {
//     display: none;
//     margin-left: 0.255em;
//     vertical-align: 0.255em;
//     content: "";
//     border-top: 0.3em solid;
//     border-right: 0.3em solid transparent;
//     border-bottom: 0;
//     border-left: 0.3em solid transparent;
// }